import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#b02519', // Your desired primary color
    },
    background: {
      default: '#ffffff', // White background
    },
  },
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          color: '#b02519', // Color for links globally
          textDecoration: 'none',
          '&:hover': {
            textDecoration: 'underline',
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: '#b02519', // Color for menu items globally
        },
      },
    },
  },
});

export default theme;
