import React, { useState } from 'react';
import { Popover, MenuItem, Box, Link, Typography, IconButton, Drawer, List, ListItem, ListItemText } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const menuItems = [
  { label: 'Home', href: '/' },
  { label: 'About Us', href: '/about-us' },
  { label: 'Rider', href: '/riders' },
  { label: 'Driver', href: '/drivers' },
  { label: 'Business', href: '/business' },
  {
    label: 'Features',
    subMenu: [
      // { label: 'Technology', href: 'template-kit/faqs/index.html' },
      // { label: 'Safety', href: 'template-kit/testimonials/index.html' },
      // { label: 'Compliance', href: 'template-kit/blog/index.html' },
      // { label: 'Coverage', href: '2023/08/03/transport-made-simple/index.html' },
      { label: 'Corporate Travel', href: '/corporate-travel' },
      // { label: 'Partnerships', href: 'template-kit/404/index.html' }
    ]
  },
  { label: 'Contact Us', href: '/contact-us' },
  {
    label: 'Login / Sign Up',
    subMenu: [
      { label: 'Sign Up', href: 'https://app.licensedtaxi.co.uk/signup',target: '_blank' },
      { label: 'Login', href: 'https://app.licensedtaxi.co.uk/', target: '_blank'}
    ]
  }
];

const NavBar = () => {
  const [subMenuAnchorEl, setSubMenuAnchorEl] = useState({});
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleSubMenuOpen = (event, label) => {
    setSubMenuAnchorEl((prev) => ({ ...prev, [label]: event.currentTarget }));
  };

  const handleSubMenuClose = (label) => {
    setSubMenuAnchorEl((prev) => ({ ...prev, [label]: null }));
  };

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const list = () => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {menuItems.map((item) => (
          <React.Fragment key={item.label}>
            <ListItem button component="a" href={item.href}>
              <ListItemText primary={item.label} />
            </ListItem>
            {item.subMenu && item.subMenu.map((subItem) => (
              <ListItem key={subItem.label} button component="a" href={subItem.href} sx={{ pl: 4 }}>
                <ListItemText primary={subItem.label} />
              </ListItem>
            ))}
          </React.Fragment>
        ))}
      </List>
    </Box>
  );

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {isMobile ? (
        <>
          <IconButton
            size="large"
            edge="end"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
          <Drawer
            anchor="right"
            open={drawerOpen}
            onClose={toggleDrawer(false)}
          >
            {list()}
          </Drawer>
        </>
      ) : (
        menuItems.map((item) => (
          <Box
            key={item.label}
            sx={{ position: 'relative', padding: '8px 16px' }}
            onMouseEnter={(event) => item.subMenu && handleSubMenuOpen(event, item.label)}
            onMouseLeave={() => item.subMenu && handleSubMenuClose(item.label)}
          >
            <Link
              href={item.href || '#'}
              sx={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: '#b02519' }}
            >
              <Typography variant="body1">
                {item.label}
              </Typography>
              {item.subMenu && (
                subMenuAnchorEl[item.label] ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />
              )}
            </Link>
            {item.subMenu && (
              <Popover
                anchorEl={subMenuAnchorEl[item.label]}
                open={Boolean(subMenuAnchorEl[item.label])}
                onClose={() => handleSubMenuClose(item.label)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                disableRestoreFocus
                sx={{ pointerEvents: 'auto' }}
              >
                {item.subMenu.map((subItem) => (
                  <MenuItem key={subItem.label} onClick={() => handleSubMenuClose(item.label)}>
                    <Link href={subItem.href} color="inherit" underline="none" target={subItem.target || '_self'}>
                      {subItem.label}
                    </Link>
                  </MenuItem>
                ))}
              </Popover>
            )}
          </Box>
        ))
      )}
    </Box>
  );
};

export default NavBar;
