import AppNavigator from './components/AppNavigator';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './config/theme';
import './App.css';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppNavigator />
    </ThemeProvider>
  );
}

export default App;
