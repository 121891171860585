import React, { useState, useEffect } from 'react';
import {
  Typography,
  Paper,
  TextField,
  Box,
  Button,
  Container,
  Grid,
  Autocomplete,
  CircularProgress,
} from '@mui/material';
import axios from 'axios';

const ContactUsForm = () => {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phone: '',
    message: '',
  });

  const [countryCodes, setCountryCodes] = useState([]);
  const [selectedCode, setSelectedCode] = useState('44');
  const [filteredCountryCodes, setFilteredCountryCodes] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState({});
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const contactUrl = `${process.env.REACT_APP_LOGIN}/contactus/form/submit`;
  const zoneId = process.env.REACT_APP_ZONE_ID;

useEffect(() => {
  const fetchCountryCodes = async () => {
    try {
      const response = await axios.get(
        'https://lgn.licensedtaxi.co.uk/customers/countrycodes/list'
      );

      console.log('API Response:', response.data); 

      const codes = Array.isArray(response.data.data) ? response.data.data : [];

      setCountryCodes(codes); // Set the formatted country codes
    } catch (error) {
      console.error('Error fetching country codes', error);
      setCountryCodes([]); // Set an empty array on error
    } finally {
      setLoading(false);
    }
  };

  fetchCountryCodes();
}, []);

  

  const handlePhoneChange = (e) => {
    const phoneValue = `${selectedCode}-${e.target.value}`;
    handleChange({ target: { name: 'phone', value: phoneValue } });
  };
  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: '' });
    if (name === 'email') {
      const emailValid = validateEmail(value);
      setErrors({
        ...errors,
        email: emailValid ? '' : 'Invalid email address',
      });
    }
  };

  const validate = () => {
    let tempErrors = {};
    tempErrors.fullName = formData.fullName ? '' : 'Name is required';
    tempErrors.email = formData.email ? '' : 'Email is required';
    tempErrors.phone = formData.phone ? '' : 'Phone number is required';
    tempErrors.message = formData.message ? '' : 'Message is required';
    setErrors(tempErrors);
    return Object.values(tempErrors).every((x) => x === '');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      try {
        const response = await axios.post(contactUrl, {
          zone_id: zoneId,
          full_name: formData.fullName,
          email_address: formData.email,
          mobile: formData.phone,
          message: formData.message,
          status: 'pending',
        });

        if (response.data.status === 'success') {
          setConfirmationMessage('Your message has been sent successfully!');
        } else {
          setConfirmationMessage('Message not sent! Please try again.');
        }
        setFormData({ fullName: '', email: '', phone: '', message: '' });
      } catch (error) {
        console.error('Error submitting form', error);
        setConfirmationMessage(
          'There was an error submitting the form. Please try again later.'
        );
      }
    }
  };
  useEffect(() => {
    const filterCodes = countryCodes.filter((code) =>
      code.country_name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredCountryCodes(filterCodes);
  }, [searchTerm, countryCodes]);
  return (
    <Container maxWidth="lg" sx={{ padding: '20px' }}>
      <Grid container spacing={4} alignItems="stretch">
        {/* Left Side - Business Benefits */}
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ padding: '20px', height: '100%' }}>
            <Typography variant="h4" gutterBottom style={{ color: '#B02519' }}>
              Business Features
            </Typography>
            <Typography variant="body1" paragraph>
            Instruct us to open an account today for easy business travel management accompanied with benefits:
            </Typography>
            <ul style={{ paddingLeft: '20px', listStyleType: 'none' }}>
            <li>✔ On-Demand and Pre-Book Rides</li>
            <li>✔ Automated Invoicing</li>
            <li>✔ Cost Control Management</li>
            <li>✔ Professional Drivers DBS Clearance</li>
            <li>✔ Local Council Fares with city - No Surcharging</li>
            <li>✔ Schedule 24/7 bookings via iOS and Android app</li>
            <li>✔ Sustainable and comfortable travel</li>
            <li>✔ Dedicated Account Managers</li>
            <li>✔ Syncs up with HR and expense management systems</li>
            <li>✔ Reporting and Safety integrated</li>
            <li>✔ One-stop travel solution catering all business needs</li>
            </ul>
            <Button
              component="a"
              href="/business-form"
              variant="contained"
              sx={{ backgroundColor: '#B02519', color: '#fff', marginTop: '20px' }}
              fullWidth
            >
              OPEN BUSINESS ACCOUNT
            </Button>
          </Paper>
        </Grid>

        {/* Right Side - Contact Us Form */}
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ padding: '20px', maxWidth: '600px', margin: 'auto' }}>
            <Typography
              variant="h4"
              gutterBottom
              sx={{ color: '#b02519', textAlign: 'center', marginBottom: '20px' }}
            >
              Business Enquiry
            </Typography>
            <form onSubmit={handleSubmit} noValidate>
              <Box mb={2}>
                <TextField
                  fullWidth
                  label="Name"
                  variant="outlined"
                  name="fullName"
                  value={formData.fullName}
                  onChange={handleChange}
                  error={!!errors.fullName}
                  helperText={errors.fullName}
                />
              </Box>
              <Box mb={2}>
                <TextField
                  fullWidth
                  label="Email"
                  variant="outlined"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  error={!!errors.email}
                  helperText={errors.email}
                />
              </Box>
                    <Box display="flex" mb={2}>
        {/* Country Code Dropdown with Search and Select */}
        <Autocomplete
          options={filteredCountryCodes}
          getOptionLabel={(option) => `${option.country_name} (+${option.mobile_code})`}
          loading={loading}
          onChange={(event, newValue) => {
            setSelectedCode(newValue ? newValue.mobile_code : '');
          }}
          inputValue={searchTerm}
          onInputChange={(event, newInputValue) => {
            setSearchTerm(newInputValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Country Code"
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? <CircularProgress size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          sx={{ width: '30%', marginRight: 1 }}
        />

        {/* Phone Number Input */}
        <TextField
          label="Phone Number"
          variant="outlined"
          value={formData.phone.split('-')[1] || ''}
          onChange={handlePhoneChange}
          error={!!errors.phone}
          helperText={errors.phone}
          sx={{ width: '70%' }}
        />
      </Box>

              <Box mb={2}>
                <TextField
                  fullWidth
                  label="Your message"
                  variant="outlined"
                  multiline
                  rows={4}
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  error={!!errors.message}
                  helperText={errors.message}
                />
              </Box>
              <Button variant="contained" color="primary" fullWidth type="submit">
                SEND
              </Button>
            </form>
            {confirmationMessage && (
              <Typography
                variant="body1"
                sx={{ marginTop: '20px', color: 'green', textAlign: 'center' }}
              >
                {confirmationMessage}
              </Typography>
            )}
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ContactUsForm;
