import React from 'react';
import { AppBar, Toolbar, Box, Container } from '@mui/material';
import NavBar from './NavBar';
import logo from '../../assets/images/logo.png';


const Header = () => {

  return (
    <header>
      <AppBar position="fixed" sx={{ backgroundColor: '#ffffff', color: '#b02519', width: '100%' }}>
        <Container>
          <Toolbar sx={{ justifyContent: 'space-between', padding: '0 16px' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', padding: '5px 0' }}>
              <img
                src={logo}
                alt="Logo"
                style={{ width: 100, height: 'auto' }}
              />
            </Box>
            <NavBar />
          </Toolbar>
        </Container>
      </AppBar>
      <Toolbar />

    </header >
  );
};

export default Header;
